import { render, staticRenderFns } from "./detail_basic.vue?vue&type=template&id=6c4cf737&"
import script from "./detail_basic.vue?vue&type=script&lang=js&"
export * from "./detail_basic.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/lib/jenkins/workspace/web-page/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6c4cf737')) {
      api.createRecord('6c4cf737', component.options)
    } else {
      api.reload('6c4cf737', component.options)
    }
    module.hot.accept("./detail_basic.vue?vue&type=template&id=6c4cf737&", function () {
      api.rerender('6c4cf737', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "found/views/sfa/views/visit_manage/visit_detail/components/detail_basic.vue"
export default component.exports
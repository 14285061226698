<script>
import TablePage from '../../../../../components/table_page';
import request from '../../../../../utils/request';
import FormDetail from './form_detail.vue';

import * as utils from '../../../utils/index';

export default {
  name: 'table-component',
  extends: TablePage,
  components: { FormDetail },
  data() {
    return {
      // requestUrl: '/sfa/sfaVisitDealerDetailController/findVisitDetailReportList',
      params: {
        visitBigType: 'VISIT,UNFAMILIAR_VISIT',
      },
      formConfig: {},
    };
  },
  methods: {
    // 判断是否有‘yearMonth’字段
    hasYearMonth() {
      const index = this.searchList.findIndex((item) => item.field === 'yearMonth');
      if (index > -1) {
        return true;
      }
      return false;
    },
    // 获取列表数据前置函数
    beforeGetList() {
      if (this.hasYearMonth()) {
        this.formData.yearMonth = this.searchFormData.yearMonth || null;
      } else if (this.searchFormData.yearMonth) {
        delete this.searchFormData.yearMonth;
      }
      return true;
    },
    /** @override 重写重置按钮 */
    beforeSearchEvent({ $event }) {
      if ($event.type === 'reset' && this.hasYearMonth()) {
        this.searchFormData.yearMonth = utils.dateFormat('YYYY-mm', new Date());
        this.formData.yearMonth = this.searchFormData.yearMonth;
      }
      return true;
    },
    modalClick({ val, row }) {
      if (val.code === 'view') return this.btnDetail(row);
    },
    btnDetail(row) {
      this.formName = 'FormDetail';
      this.formConfig.row = { ...row };
      this.modalConfig.title = '查看 详情';
      this.openFull();
    },
  },
  created() {
    this.searchFormData.yearMonth = utils.dateFormat('YYYY-mm', new Date());
    this.getConfigList('sfaCenter_visitManage_visitDetail_list');
  },

};
</script>
